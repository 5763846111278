import React from 'react';
import { Link } from 'gatsby';
import '../assets/styles/base/standard-page.scss';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';

const BlogSingle = ({ pageContext: { blog } }) => {
  const schema = () => {
    return {
      "@context": "http://schema.org/",
      "@type": "BlogPosting",
      "headline": "Myths and Facts of DIY Car Dent Removal",
      "datePublished": "2019-12-05T10:52:00+1100",
      "dateModified": "2021-02-16T10:16:00+1100",
      "author": {
        "@type": "Organization",
        "name": "DingGo"
      },
      "publisher": {
        "@type": "Organization",
        "name": "DingGo",
        "Logo": {
          "@type": "ImageObject",
          "url": "https://www.dinggo.com.au/img/DingGo-Logo-White.png"
        }
      },
      "mainEntityOfPage": {
        "@type":
          "WebPage",
        "@id": "https://dinggo.com.au/blog/myths-and-facts-of-diy-car-dent-removal"
      },
      "image": {
        "@type": "ImageObject",
        "url": "https://dinggo.com.au/img/blog/myths-and-facts-of-diy-car-dent-removal.png",
        "width": 800,
        "height": 300
      }
    };
  };
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Snap. Compare. Repair." description="Car Ding? DingGo. Compare quotes online & book car repairs from local panel beaters & smash repairs in Sydney. Stop driving around for quotes & paying too much." />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema())}
        </script>
      </Helmet>
      <AnouncementBar />
      <div className="hero-banner">
        <div className="container text-left">
          <h1 className="mb-4">{blog.title}</h1>
          <p>
            {blog.subTitle}
          </p>
        </div>
      </div>
      <nav className="breadcrumb" aria-label="Breadcrumb">
        <ol className="breadcrumb__list">
          <li className="breadcrumb__item">
            <Link className="breadcrumb__link" href="/">DingGo</Link>
            <span className="breadcrumb__separator" aria-hidden="true"> / </span>
          </li>
          <li className="breadcrumb__item">
            <Link className="breadcrumb__link" href="/blog/">blog</Link>
            <span className="breadcrumb__separator" aria-hidden="true"> / </span>
          </li>
          <li className="breadcrumb__item">
            <span className="breadcrumb__link breadcrumb__link__active">
              Blog One
            </span>
          </li>
        </ol>
      </nav>
      <div className="py-5 bg-light">
        <div className="container main-wrapper">
          <div className="row mb-5">
            <div
              className="col-10"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: blog.body,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

BlogSingle.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default BlogSingle;
